/* General Styling */
.studios-section {
  padding: 40px 20px 90px 20px;
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(
    rgba(249, 249, 249, 1), /* Darker overlay color at the top */
    rgba(0, 0, 0, 0.2)  /* Lighter overlay color at the bottom */
  ), 
  url("../../imgs/studio-bg.jpg") center center/cover no-repeat;
}


/* Added Container to Limit Width */
.studios-container {
  max-width: 1570px; /* Limit the maximum width */
  margin: 0 auto; /* Center the container */
  width: 100%; /* Ensure it scales responsively */
  height: 100%;
}

.studios-header {
  text-align: center;
  margin-bottom: 30px;
}

.studios-title {
  font-size: 56px;
  font-weight: bold;
  color: #2c3e50;
  text-transform: uppercase;
}

.studios-title-divider{
  width: 85%;
  height: 3px;
  background-color: #b6b6b62c;
  margin: 0 auto 50px auto;
  animation: slideIn 1.5s ease-in-out;
}

.studios-subtitle {
  font-size: 16px;
  color: #7f8c8d;
  margin-top: 0px;
}

.studios-layout {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  align-items: center; /* Vertically centers both the image and details */
  
}

/* Studio List */
.studio-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.studio-item {
  padding: 12px;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.studio-item.active {
  background: #f92525;
  color: #ffffff;
  border: none;
}

.studio-item:hover {
  background: #f92525;
  color: #ffffff;
  border: none;
}

/* Studio Image */
.studio-image {
  display: flex; /* Ensures proper alignment */
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically */
}

.studio-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Studio Details */
.studio-details {
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Keep the content stacked vertically */
  justify-content: center; /* Vertically center the content */
  align-items: flex-start; /* Align text to the left */
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  max-width: 400px; /* Limit the maximum width */
  width: 100%; /* Ensure it scales responsively */
}

.studio-name {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  text-transform: uppercase;
}

.studio-description {
  font-size: 16px;
  color: #555;
  margin: 15px 0;
}

.studio-specs {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.studio-specs li {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.studio-rate,
.studio-minimum {
  font-size: 16px;
  color: #2c3e50;
  font-weight: bold;
}

.studio-engineer {
  font-size: 14px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 15px;
}

.book-now-button {
  background: #f92525;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.book-now-button:hover {
  background: #d81b1b;
}

/* Responsive Design */
@media (max-width:1356px) {
  .studios-layout {
    grid-template-columns: 1fr; /* Stack all elements vertically */
  }

  .studio-list {
    flex-direction: row; /* Display the list items in a row */
    overflow-x: auto; /* Allow horizontal scrolling for the list */
    gap: 5px;
    justify-content: center; /* Center the list horizontally */
    margin-top: 20px; /* Add some spacing above the list */
  }

  .studio-image {
    order: 1; /* Ensure the image comes first */
  }

  .studio-image img {
    width: 60%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }

  .studio-list {
    order: 2; /* Ensure the studio list comes underneath the image */
  }

  .studio-details {
    order: 3; /* Ensure the studio details come last */
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Center-align the text */
    display: flex; /* Use flexbox for vertical centering */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    min-height: 200px; /* Optional: Set a minimum height */
  }
  
}
/* Responsive Design */
@media (max-width: 1081px) {
  .studios-layout {
    grid-template-columns: 1fr; /* Stack all elements vertically */
  }

  .studio-list {
    flex-direction: row; /* Display the list items in a row */
    overflow-x: auto; /* Allow horizontal scrolling for the list */
    gap: 5px;
    justify-content: center; /* Center the list horizontally */
    margin-top: 20px; /* Add some spacing above the list */
  }

  .studio-image {
    order: 1; /* Ensure the image comes first */
  }

  .studio-list {
    order: 2; /* Ensure the studio list comes underneath the image */
  }

  .studio-details {
    order: 3; /* Ensure the studio details come last */
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Center-align the text */
    display: flex; /* Use flexbox for vertical centering */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    min-height: 200px; /* Optional: Set a minimum height */
  }
}

@media (max-width: 590px){
  .studios-title{
    font-size: 45px;
  }
}