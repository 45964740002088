/* General Styling */
.product-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px;
  background-color: #f9f9f9;
  flex-wrap: wrap; /* Enables wrapping for smaller screens */
}

/* Image Section */
.image-section img {
  width: 100%;
  max-width: 500px;
  height: 700px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Details Section */
.details-section {
  margin-left: 20px;
  max-width: 500px;
  width: 100%; /* Makes it responsive */
}

.product-title {
  font-size: 32px;
  font-weight: bold;
  color: #2a2a2a;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666;
}

/* Features Section */
.features-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.feature-icon {
  font-size: 36px;
  color: #2d2d2dce;
}

.feature-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.feature-description {
  font-size: 14px;
  color: #666;
}

/* Responsive Styling */

/* Medium Screens (Tablets) */
@media (max-width: 1024px) {
  .product-card {
    flex-direction: column;
    text-align: center;
  }

  .image-section img {
    max-width: 100%;
    height: auto;
  }

  .details-section {
    margin-left: 0;
    max-width: 100%;
  }

  .product-title {
    font-size: 28px;
  }

  .product-description {
    font-size: 14px;
  }

  .feature-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .feature-icon {
    font-size: 32px;
  }

  .feature-title {
    font-size: 16px;
  }

  .feature-description {
    font-size: 13px;
  }
}

/* Small Screens (Phones) */
@media (max-width: 480px) {
  .product-card {
    padding: 20px;
    gap: 15px;
  }

  .product-title {
    font-size: 24px;
  }

  .product-description {
    font-size: 13px;
    line-height: 1.4;
  }

  .features-container {
    gap: 15px;
  }

  .feature-item {
    gap: 10px;
  }

  .feature-icon {
    font-size: 28px;
  }

  .feature-title {
    font-size: 14px;
  }

  .feature-description {
    font-size: 12px;
  }
}