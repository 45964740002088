@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");

/* Booking Section */
.booking-section {
  font-family: "Oswald", sans-serif;
}

/* Banner Section */
.booking-banner {
  position: relative;
  height: 40vh;
  background: linear-gradient(
    rgba(255, 255, 255, 0.705),
    rgba(0, 0, 0, 0.1)
  ), url("../../imgs/artists_walls.jpg") center center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  overflow: hidden;
}

.booking-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.banner-content {
  position: relative;
  z-index: 2;
}

.banner-title {
  font-size: 50px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: white;
}

/* Booking Main */
.booking-main {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 40px auto;
  padding: 20px;
  max-width: 1200px;
}

.booking-left,
.booking-right {
  flex: 1;
}

/* Contact Form */
.booking-left {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  color: #000;
  animation: slideIn 1.2s ease-in-out;
}

.form-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #000;
}

.booking-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
}

.booking-form input,
.booking-form select,
.booking-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.radio-group label {
  font-size: 14px;
  color: #333;
}

.radio-group input[type="radio"] {
  margin: 0;
  accent-color: #f92525;
}

.submit-button {
  background: #f92525;
  color: white;
  padding: 12px;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.submit-button:hover {
  background: #d11414;
}

/* Banner and Details */
.booking-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slideInRight 1.2s ease-in-out;
}

.booking-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.booking-details {
  margin-top: 20px;
  text-align: center;
  color: #000;
}

.view-more-button {
    display: inline-flex;
    align-items: center; /* Vertically align the text and arrow */
    color: #333; /* Black text color */
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none; /* Remove default underline */
    border-bottom: 2px solid transparent; /* Underline effect */
    margin-top: 10px;
    padding-left: 60px;
    cursor: pointer; /* Add pointer on hover */
    transition: color 0.3s ease, border-bottom-color 0.3s ease; /* Smooth transition */
    position: relative; /* For arrow positioning */
    background-color: rgba(239, 239, 239, 0.497);
    border-radius: 5px;
    width: 189px;
    height: 54px;
    border: #000;
  }
  
  .view-more-button::after {
    content: "→"; /* Right arrow */
    margin-left: 8px; /* Add space between text and arrow */
    font-size: 16px; /* Slightly larger arrow size */
    opacity: 0; /* Hide arrow initially */
    transform: translateX(-5px); /* Slightly offset arrow */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for arrow */
  }
  
  .view-more-button:hover {
    color: #f92525; /* Change text color on hover */
    border-bottom-color: #f92525; /* Add underline color on hover */
    background-color: rgba(219, 217, 217, 0.364);
    
  }
  
  .view-more-button:hover::after {
    opacity: 1; /* Show arrow on hover */
    transform: translateX(0); /* Bring arrow to its normal position */
   
  }

/* Animations */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-main {
    flex-direction: column;
  }

  .booking-left,
  .booking-right {
    width: 100%;
  }
}