@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@300;400;700&display=swap);
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background-color: transparent; /* Transparent by default */
  box-sizing: border-box; /* Ensure consistent padding */
 
}

.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.886); /* Light background when scrolled */
}

.navbar.scrolled .navbar-left a,
.navbar.scrolled .navbar-right a {
  color: black; /* Black text when scrolled */
  font-family: 'Archivo', sans-serif;
}

.navbar.scrolled .menu-icon {
  color: black; /* Black menu icon when scrolled */
  font-family: 'Archivo', sans-serif;
}

.navbar-left,
.navbar-center,
.navbar-right {
  flex: 1 1; /* Equal space for each section */
  display: flex;
  align-items: center;
}

.navbar-left {
  justify-content: flex-start;
}

.navbar-center {
  justify-content: center;
}

.navbar-right {
  justify-content: flex-end; /* Align links to the right */
}

.logo-image {
  height: 40px;
  display: block;
}

.navbar-left a,
.navbar-right a {
  position: relative; /* Needed for the pseudo-element */
  color: rgb(199, 199, 199); /* Default text color */
  margin: 0 15px;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: color 0.3s ease; /* Smooth color transition */
  font-family: 'Archivo', sans-serif;
}

.navbar-left a::after,
.navbar-right a::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Position the underline just below the text */
  left: 50%; /* Start underline from the center */
  width: 0; /* Start with no width */
  height: 2px; /* Thickness of the underline */
  background-color: #ea0000; /* Red underline color */
  transform: translateX(-50%); /* Center-align the underline */
  transition: width 0.3s ease; /* Smooth expand effect */
}

.navbar-left a:hover,
.navbar-right a:hover {
  color: #e4e4e4; /* Change text color on hover */
}

.navbar-left a:hover::after,
.navbar-right a:hover::after {
  width: 100%; /* Expand the underline to match the text width */
}

/* Mobile Navbar Styles */
.navbar-mobile {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.navbar-menu {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 999;
  padding: 20px;
}

.navbar-menu.open {
  display: flex;
}

.navbar-menu a {
  color: white;
  font-family: 'Archivo', sans-serif;
  margin: 10px 0;
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.navbar-menu a:hover {
  color: #edebeb;
}

.social-icons {
  display: flex; /* Use flexbox for alignment */
  grid-gap: 15px;
  gap: 15px; /* Add spacing between icons */
  margin-top: 20px; /* Add spacing above the icons */
}

.social-icons a {
  color: white; /* Default icon color */
  font-size: 24px; /* Size of the icons */
  transition: color 0.3s ease; /* Smooth transition for hover */
}

.social-icons a:hover {
  color: #f92525; /* Change icon color on hover */
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .navbar {
    background-color: #ffffff;
  }

  .menu-icon {
    color: black;
  }

  .navbar-left,
  .navbar-center,
  .navbar-right {
    display: none;
  }

  .navbar-mobile {
    display: flex;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  z-index: auto;
  
}

body {
/* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
background:white;

}

.hero-wrap {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5)), 
                url(/static/media/the-lab3.b23ccb7f.jpg) center center/cover no-repeat; /* Two-tone gradient overlay */
}

/* Optional animation for the gradient overlay */
.hero-wrap::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.2)); /* Subtle gradient animation */
    z-index: 1;
     
}


.hero-container {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: flex-start; /* Aligns content to the left */
    height: 100%;
    width: 100%;
    color: white;
    padding: 20px 40px; /* Adjusted padding */
    animation: slideUp 1.5s ease-in-out 0.5s forwards; /* Slide-up effect with delay */
}

/* Text content styling */
.text-content {
    max-width: 640px;
    margin-bottom: 30px; /* Spacing below text content */
}

/* Apply the typing effect */
.hero-container h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 48px; /* Default size */
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid white;
    opacity: 0; /* Initially hide the text */
    animation: typingEffect 3s steps(30, end) 0.5s forwards, blinkCursor 0.5s step-end infinite; /* Add delay of 0.5s */
    max-width: 100%;
    animation-fill-mode: forwards; /* Retain final state after animation */
}

/* Stop cursor blink after typing */
.hero-container h1.typed {
    border-right: none; /* Remove the cursor after the animation finishes */
    animation: none; /* Disable further animations */
}

.text-content p {
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0;
    color: #ececec; /* Subtle contrast color */
    opacity: 0; /* Hidden initially for animation */
    animation: textFadeUp 4.3s ease-in-out 1s forwards; /* Staggered fade-up for the paragraph */
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes overlayFade {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.5);
    }
}


@keyframes textFadeUp {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Typing animation */
@keyframes typingEffect {
    0% {
        width: 0; /* Start with no visible text */
        opacity: 1; /* Make the text visible during animation */
    }
    100% {
        width: 100%; /* Fully reveal the text */
        opacity: 1; /* Ensure the text remains visible */
    }
}

/* Cursor blinking animation */
@keyframes blinkCursor {
    0% {
        border-right-color: transparent;
    }
    50% {
        border-right-color: white;
    }
    100% {
        border-right-color: transparent;
    }
}

@keyframes typingEffectMobile {
    from {
        width: 0; /* Start with no visible text */
        opacity: 0; /* Make the text visible during animation */
    }
    to {
        width: 58%; /* Reveal the entire text */
        opacity: 1; /* Make the text visible during animation */
    }
}

/* Responsive font sizes */
@media (max-width: 480px) {
    .hero-container {
        padding: 15px 5px; /* Further reduce padding */
    }

    .hero-container h1 {
        font-size: 22px; /* Reduce font size further */
        animation: typingEffectMobile 3s steps(30, end) forwards, blinkCursor 0.5s step-end infinite;
        /* animation: typingEffect 3s steps(30, end) 0.5s forwards, blinkCursor 0.5s step-end infinite; Add delay of 0.5s */
    }

    .text-content p {
        font-size: 12px; /* Reduce paragraph size */
    }

   
}

@media (min-width: 768px) {
    .hero-container h1 {
        font-size: 63px; /* Medium screens */
        
    }
    .text-content p {
        font-size: 20px; /* Medium screens */
    }
}

@media (min-width: 992px) {
    .hero-container h1 {
        font-size: 63px; /* Large screens */
    }
    .text-content p {
        font-size: 22px; /* Large screens */
    }
}

@media (min-width: 1200px) {
    .hero-container h1 {
        font-size: 64px; /* Extra large screens */
    }
    .text-content p {
        font-size: 24px; /* Extra large screens */
    }
}



/* General Styling */
.studios-section {
  padding: 40px 20px 90px 20px;
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(
    rgba(249, 249, 249, 1), 
    rgba(0, 0, 0, 0.2)  
  ), 
  url(/static/media/studio-bg.d43b7a0f.jpg) center center/cover no-repeat;
}


/* Added Container to Limit Width */
.studios-container {
  max-width: 1570px; /* Limit the maximum width */
  margin: 0 auto; /* Center the container */
  width: 100%; /* Ensure it scales responsively */
  height: 100%;
}

.studios-header {
  text-align: center;
  margin-bottom: 30px;
}

.studios-title {
  font-size: 56px;
  font-weight: bold;
  color: #2c3e50;
  text-transform: uppercase;
}

.studios-title-divider{
  width: 85%;
  height: 3px;
  background-color: #b6b6b62c;
  margin: 0 auto 50px auto;
  animation: slideIn 1.5s ease-in-out;
}

.studios-subtitle {
  font-size: 16px;
  color: #7f8c8d;
  margin-top: 0px;
}

.studios-layout {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 20px;
  gap: 20px;
  align-items: center; /* Vertically centers both the image and details */
  
}

/* Studio List */
.studio-list {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.studio-item {
  padding: 12px;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.studio-item.active {
  background: #f92525;
  color: #ffffff;
  border: none;
}

.studio-item:hover {
  background: #f92525;
  color: #ffffff;
  border: none;
}

/* Studio Image */
.studio-image {
  display: flex; /* Ensures proper alignment */
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically */
}

.studio-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Studio Details */
.studio-details {
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Keep the content stacked vertically */
  justify-content: center; /* Vertically center the content */
  align-items: flex-start; /* Align text to the left */
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  max-width: 400px; /* Limit the maximum width */
  width: 100%; /* Ensure it scales responsively */
}

.studio-name {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  text-transform: uppercase;
}

.studio-description {
  font-size: 16px;
  color: #555;
  margin: 15px 0;
}

.studio-specs {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.studio-specs li {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.studio-rate,
.studio-minimum {
  font-size: 16px;
  color: #2c3e50;
  font-weight: bold;
}

.studio-engineer {
  font-size: 14px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 15px;
}

.book-now-button {
  background: #f92525;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.book-now-button:hover {
  background: #d81b1b;
}

/* Responsive Design */
@media (max-width:1356px) {
  .studios-layout {
    grid-template-columns: 1fr; /* Stack all elements vertically */
  }

  .studio-list {
    flex-direction: row; /* Display the list items in a row */
    overflow-x: auto; /* Allow horizontal scrolling for the list */
    grid-gap: 5px;
    gap: 5px;
    justify-content: center; /* Center the list horizontally */
    margin-top: 20px; /* Add some spacing above the list */
  }

  .studio-image {
    order: 1; /* Ensure the image comes first */
  }

  .studio-image img {
    width: 60%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }

  .studio-list {
    order: 2; /* Ensure the studio list comes underneath the image */
  }

  .studio-details {
    order: 3; /* Ensure the studio details come last */
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Center-align the text */
    display: flex; /* Use flexbox for vertical centering */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    min-height: 200px; /* Optional: Set a minimum height */
  }
  
}
/* Responsive Design */
@media (max-width: 1081px) {
  .studios-layout {
    grid-template-columns: 1fr; /* Stack all elements vertically */
  }

  .studio-list {
    flex-direction: row; /* Display the list items in a row */
    overflow-x: auto; /* Allow horizontal scrolling for the list */
    grid-gap: 5px;
    gap: 5px;
    justify-content: center; /* Center the list horizontally */
    margin-top: 20px; /* Add some spacing above the list */
  }

  .studio-image {
    order: 1; /* Ensure the image comes first */
  }

  .studio-list {
    order: 2; /* Ensure the studio list comes underneath the image */
  }

  .studio-details {
    order: 3; /* Ensure the studio details come last */
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Center-align the text */
    display: flex; /* Use flexbox for vertical centering */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    min-height: 200px; /* Optional: Set a minimum height */
  }
}

@media (max-width: 590px){
  .studios-title{
    font-size: 45px;
  }
}
.projects-section {
  background: url(/static/media/artists_walls.266707d0.jpg) center/cover no-repeat;
  position: relative;
  text-align: center;
  padding: 80px 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.projects-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.4), /* Darker overlay */
      rgba(0, 0, 0, 0.6)
    ),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
  opacity: 1;
  z-index: 0;
}

.content-container {
  z-index: 2;
  max-width: 600px;
  position: relative;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px; /* Smooth corners for better aesthetics */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for better contrast */
}

.headline {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: #ffffff; /* Change text color to white for contrast */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8); /* Add text shadow */
}

.subheadline {
  font-size: 18px;
  color: #dddddd; /* Subtle color for subheadline text */
  margin-top: 10px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8); /* Add text shadow */
}

.cover-art-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
}

.cover-art {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: float 6s ease-in-out infinite;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}

.cover-art:hover {
  transform: scale(1.1);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .headline {
    font-size: 32px;
  }
  .subheadline {
    font-size: 14px;
  }
  .cover-art {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 480px) {
  .headline {
    font-size: 28px;
  }
  .subheadline {
    font-size: 12px;
  }
  .cover-art {
    width: 70px;
    height: 70px;
  }
}
/* General Styling */
.product-card {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 40px;
  background-color: #f9f9f9;
  flex-wrap: wrap; /* Enables wrapping for smaller screens */
}

/* Image Section */
.image-section img {
  width: 100%;
  max-width: 500px;
  height: 700px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Details Section */
.details-section {
  margin-left: 20px;
  max-width: 500px;
  width: 100%; /* Makes it responsive */
}

.product-title {
  font-size: 32px;
  font-weight: bold;
  color: #2a2a2a;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666;
}

/* Features Section */
.features-container {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
}

.feature-icon {
  font-size: 36px;
  color: #2d2d2dce;
}

.feature-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.feature-description {
  font-size: 14px;
  color: #666;
}

/* Responsive Styling */

/* Medium Screens (Tablets) */
@media (max-width: 1024px) {
  .product-card {
    flex-direction: column;
    text-align: center;
  }

  .image-section img {
    max-width: 100%;
    height: auto;
  }

  .details-section {
    margin-left: 0;
    max-width: 100%;
  }

  .product-title {
    font-size: 28px;
  }

  .product-description {
    font-size: 14px;
  }

  .feature-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .feature-icon {
    font-size: 32px;
  }

  .feature-title {
    font-size: 16px;
  }

  .feature-description {
    font-size: 13px;
  }
}

/* Small Screens (Phones) */
@media (max-width: 480px) {
  .product-card {
    padding: 20px;
    grid-gap: 15px;
    gap: 15px;
  }

  .product-title {
    font-size: 24px;
  }

  .product-description {
    font-size: 13px;
    line-height: 1.4;
  }

  .features-container {
    grid-gap: 15px;
    gap: 15px;
  }

  .feature-item {
    grid-gap: 10px;
    gap: 10px;
  }

  .feature-icon {
    font-size: 28px;
  }

  .feature-title {
    font-size: 14px;
  }

  .feature-description {
    font-size: 12px;
  }
}
/* General Footer Styling */
/* General Footer Styling */
.simple-footer {
  background-color: #fff; /* White background */
  color: #000; /* Black text */
  text-align: center;
  padding: 40px 20px; /* Increase top padding for overall spacing */
  border-top: 1px solid #ddd; /* Subtle top border */
}

/* Logo Section */
.footer-logo-container {
  margin-bottom: 30px; /* Increase spacing below the logo */
  margin-top: 20px; /* Add spacing above the logo */
}

.footer-logo {
  max-width: 250px; /* Adjusted for smaller screens */
  display: block;
  margin: 0 auto;
  height: auto;
}

/* Navigation Section */
.footer-nav {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px; /* Increase spacing between navigation links */
  flex-wrap: wrap; /* Wrap items to the next line if necessary */
  margin-top: 20px; /* Add spacing above the navigation */
  margin-bottom: 30px; /* Add spacing below the navigation */
}

.footer-nav a {
  color: #000; /* Black text */
  text-decoration: none; /* Remove default underline */
  font-size: 14px;
  /* font-weight: bold; */
  text-transform: uppercase;
  position: relative; /* Required for the pseudo-element */
  display: inline-block; /* Prevents pseudo-element from extending */
  transition: color 0.3s ease; /* Smooth color transition */
  text-align: center; /* Center-align the text */
  font-family: 'Archivo', sans-serif;
}

.footer-nav a::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Position the underline just below the text */
  left: 50%; /* Start underline from the center */
  width: 0; /* Start with no width */
  height: 2px; /* Thickness of the underline */
  background-color: #ea0000; /* Red underline color */
  transform: translateX(-50%); /* Center-align the underline */
  transition: width 0.3s ease; /* Smooth expand effect */
}

.footer-nav a:hover {
  color: #ea0000; /* Change text color on hover */
}

.footer-nav a:hover::after {
  width: 100%; /* Expand the underline to match the text width */
}

/* Bottom Section */
.footer-bottom {
  font-size: 12px;
  color: #666; /* Light gray text */
  border-top: 1px solid #ddd; /* Subtle top border for bottom section */
  padding-top: 20px; /* Increase spacing above the bottom section */
  font-family: 'Archivo', sans-serif;
  text-transform: uppercase;
  margin-top: 30px; /* Add spacing above the footer-bottom */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-logo {
    max-width: 250px; /* Adjust the logo size for smaller screens */
  }

  .footer-nav {
    grid-gap: 20px;
    gap: 20px; /* Adjust spacing between navigation links */
    flex-direction: column; /* Stack navigation links vertically */
    margin-top: 15px; /* Adjust spacing above for smaller screens */
    margin-bottom: 20px; /* Adjust spacing below for smaller screens */
  }

  .footer-nav a {
    font-size: 14px; /* Slightly smaller font size for links */
  }

  .footer-bottom {
    font-size: 10px; /* Adjust font size for better readability on smaller screens */
    margin-top: 20px; /* Adjust spacing above footer-bottom */
  }
}

@media (max-width: 480px) {
  .footer-logo {
    max-width: 200px; /* Smaller logo for very small screens */
  }

  .footer-nav {
    grid-gap: 15px;
    gap: 15px; /* Further adjust spacing for tighter spaces */
  }

  .footer-nav a {
    font-size: 13px; /* Smaller font for links */
  }

  .footer-bottom {
    font-size: 9px; /* Further adjust font size for smaller screens */
  }
}
/* General styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #fff;
  margin: 0;
  padding: 0;
}

.video-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 20px; /* Added top padding to prevent overlap */
}

/* Main video styles */
.main-video {
  position: relative;
  margin-bottom: 20px;
}

.main-video-thumbnail {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.main-video-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 8px;
}

.main-video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
}

.main-video-title {
  font-size: 36px;
  font-weight: 700;
}

.main-video-play-icon {
  font-size: 64px;
  color: #fff;
  margin-top: 10px;
}

/* Video section styles */
.video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.video-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, box-shadow 0.3s;
}

.video-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
}

.video-thumbnail {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.video-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 8px;
}

.video-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Loading message styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

/* End message styles */
.end-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #b3b3b3;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .video-page-container {
    padding: 70px 20px 20px; /* Increase top padding for mobile */
  }

  .main-video-title {
    font-size: 28px;
  }

  .main-video-play-icon {
    font-size: 48px;
  }

  .video-title {
    font-size: 16px;
  }
}
/* Container Styles */
.services-container {
  padding: 100px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Oswald", sans-serif;
  overflow: hidden; /* Ensure animations stay within bounds */
}

/* Intro Section */
.services-intro {
  text-align: center;
  margin-bottom: 50px;
  animation: fadeIn 1.5s ease-in-out;
}

.intro-title {
  font-size: 36px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.intro-description {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* Services Section */
.services-title {
  font-size: 50px;
  text-align: center;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  animation: slideIn 1.5s ease-in-out;
}

.services-divider {
  width: 35%;
  height: 3px;
  background-color: #f92525;
  margin: 0 auto 50px auto;
  animation: slideIn 1.5s ease-in-out;
}

/* Services Section with Improved Divider */
.service-item {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 60px;
  position: relative;
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start slightly shifted */
  animation: slideUp 1.2s ease-in-out forwards;
}

.service-item:nth-child(even) {
  flex-direction: row-reverse;
}

.service-item:nth-child(odd) {
  animation-delay: 0.3s; /* Staggered animation for odd rows */
}

.service-item:nth-child(even) {
  animation-delay: 0.6s; /* Staggered animation for even rows */
}

/* Divider Styling */
.service-item {
  border-left: 2px solid #ce3030;
}

.service-item:nth-child(even) {
  border-right: 2px solid #ce3030;
  border-left: none;
}

/* Text Content Styling */
.service-text {
  max-width: 600px;
  flex: 1 1;
  padding: 0 20px;
}

.service-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
  animation: fadeIn 1.2s ease-in-out;
}

.service-description {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  animation: fadeIn 1.5s ease-in-out;
}

/* Image Styling */
.service-image {
  flex: 1 1;
  max-width: 500px;
  animation: zoomIn 1.5s ease-in-out;
}

.service-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.book-session-container {
  text-align: center;
  margin-top: 40px;
}

@keyframes smoothBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Slight upward movement */
  }
}

.book-session-container {
  text-align: center;
  margin-top: 40px;
}

.book-session-button {
  display: inline-block;
  background-color: #f92525;
  color: white;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  animation: smoothBounce 3s ease-in-out infinite; /* Smooth bounce with easing */
}

.book-session-button:hover {
  background-color: #d11414;
  transform: translateY(-2px); /* Subtle hover lift effect */
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .service-item {
    flex-direction: column;
    text-align: center;
    grid-gap: 30px;
    gap: 30px;
    border: none;
  }

  .service-text {
    max-width: 100%;
    padding: 0;
    text-align: center;
  }

  .service-image {
    padding: 0;
  }

  .service-image img {
    margin: 0 auto;
  }

  .intro-title {
    font-size: 28px;
  }

  .intro-description {
    font-size: 16px;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
/* Booking Section */
.booking-section {
  font-family: "Oswald", sans-serif;
}

/* Banner Section */
.booking-banner {
  position: relative;
  height: 40vh;
  background: linear-gradient(
    rgba(255, 255, 255, 0.705),
    rgba(0, 0, 0, 0.1)
  ), url(/static/media/artists_walls.266707d0.jpg) center center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  overflow: hidden;
}

.booking-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.banner-content {
  position: relative;
  z-index: 2;
}

.banner-title {
  font-size: 50px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: white;
}

/* Booking Main */
.booking-main {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  margin: 40px auto;
  padding: 20px;
  max-width: 1200px;
}

.booking-left,
.booking-right {
  flex: 1 1;
}

/* Contact Form */
.booking-left {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  color: #000;
  animation: slideIn 1.2s ease-in-out;
}

.form-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #000;
}

.booking-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
}

.booking-form input,
.booking-form select,
.booking-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 20px;
}

.radio-group label {
  font-size: 14px;
  color: #333;
}

.radio-group input[type="radio"] {
  margin: 0;
  accent-color: #f92525;
}

.submit-button {
  background: #f92525;
  color: white;
  padding: 12px;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.submit-button:hover {
  background: #d11414;
}

/* Banner and Details */
.booking-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slideInRight 1.2s ease-in-out;
}

.booking-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.booking-details {
  margin-top: 20px;
  text-align: center;
  color: #000;
}

.view-more-button {
    display: inline-flex;
    align-items: center; /* Vertically align the text and arrow */
    color: #333; /* Black text color */
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none; /* Remove default underline */
    border-bottom: 2px solid transparent; /* Underline effect */
    margin-top: 10px;
    padding-left: 60px;
    cursor: pointer; /* Add pointer on hover */
    transition: color 0.3s ease, border-bottom-color 0.3s ease; /* Smooth transition */
    position: relative; /* For arrow positioning */
    background-color: rgba(239, 239, 239, 0.497);
    border-radius: 5px;
    width: 189px;
    height: 54px;
    border: #000;
  }
  
  .view-more-button::after {
    content: "\2192"; /* Right arrow */
    margin-left: 8px; /* Add space between text and arrow */
    font-size: 16px; /* Slightly larger arrow size */
    opacity: 0; /* Hide arrow initially */
    transform: translateX(-5px); /* Slightly offset arrow */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for arrow */
  }
  
  .view-more-button:hover {
    color: #f92525; /* Change text color on hover */
    border-bottom-color: #f92525; /* Add underline color on hover */
    background-color: rgba(219, 217, 217, 0.364);
    
  }
  
  .view-more-button:hover::after {
    opacity: 1; /* Show arrow on hover */
    transform: translateX(0); /* Bring arrow to its normal position */
   
  }

/* Animations */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-main {
    flex-direction: column;
  }

  .booking-left,
  .booking-right {
    width: 100%;
  }
}
.addons-section {
    font-family: 'Oswald', sans-serif;
    padding: 80px 20px;
    background-color: #f8f9fa; /* Light gray background for contrast */
  }
  
  .addons-content {
    text-align: center;
    margin-bottom: 40px;
  }

  .addon-header {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .addon-icon {
    font-size: 24px; /* Adjust icon size */
    color: #f92525; /* Match your color scheme */
  }
  
  .addons-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .addons-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  /* Addons Grid */
  .addons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  
  /* Addon Cards */
  .addon-card {
    background-color: #ffffff; /* White background for the cards */
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .addon-card:hover {
    transform: translateY(-5px); /* Lift card slightly on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }
  
  .addon-title {
    font-size: 20px;
    font-weight: 700;
    color: #f92525; /* Red for emphasis */
    margin-bottom: 10px;
  }
  
  .addon-description {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
  }
  
  /* Pricing Section */
  .pricing-section {
    text-align: center;
  }
  
  .pricing-title {
    font-size: 32px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .pricing-subtitle {
    font-size: 20px;
    margin-bottom: 30px;
    color: #555;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  
  .pricing-card {
    background: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .plan-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .plan-duration {
    font-size: 14px;
    color: #bbb;
    margin-bottom: 20px;
  }
  
  .plan-details {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .plan-details li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .buy-now-button {
    background: #5b5eff;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .buy-now-button:hover {
    background: #4646d6;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .addons-grid,
    .pricing-grid {
      grid-template-columns: 1fr;
    }
  }
@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(20px); /* Slight downward offset for animation */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.biography-section {
  background: linear-gradient(to bottom, #ffffff, #f0f0f0);
  padding: 80px 40px;
  color: #222;
}

.biography-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 50px;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.biography-image img {
  width: 100%;
  height: auto;
  max-width: 450px;
  border-radius: 20px;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  animation: fadeInText 1s ease-in-out;
}

.biography-content {
  flex: 1 1;
  max-width: 600px;
}

.biography-title {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: #111;
  margin-bottom: 20px;
  text-transform: capitalize;
  border-left: 5px solid #fb196c;
  padding-left: 15px;
  animation: fadeInText 1s ease-in-out;
  text-transform: uppercase;
}

.biography-text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 25px;
  text-align: justify;
  animation: fadeInText 1.2s ease-in-out;
}

.biography-quote {
  position: relative;
  padding: 40px 20px;
  margin: 40px auto;
  max-width: 800px;
  background: linear-gradient(135deg, rgba(251, 25, 108, 0.1), rgba(251, 176, 59, 0.1));
  border-left: 5px solid #fb196c; /* Gradient accent line */
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #333;
  animation: fadeInText 1.4s ease-in-out;
}

.biography-quote::before {
  content: "\201C";
  position: absolute;
  top: -20px;
  left: 20px;
  font-size: 50px;
  color: rgba(251, 25, 108, 0.3); /* Light accent color for decorative quotation mark */
  font-weight: bold;
}

.biography-quote p {
  font-size: 20px;
  line-height: 1.8;
  font-weight: 400;
  margin: 0;
  padding: 10px 0;
}

.biography-quote-author {
  font-size: 18px;
  font-weight: 600;
  color: #fb196c;
  margin-top: 20px;
  text-transform: uppercase;
  animation: fadeInText 1.6s ease-in-out;
}

.biography-highlight {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #fb196c;
  text-align: justify;
  margin-top: 30px;
  line-height: 1.8;
}

.biography-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid layout */
  grid-gap: 20px;
  gap: 20px;
  margin-top: 40px;
}


.gallery-item {
  width: 100%;
  height: 200px; /* Set a fixed height for all items */
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 100%; /* Match the container's height */
  object-fit: cover; /* Ensure the image covers the entire area without distortion */
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.gallery-item:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Add depth on hover */
}

.gallery-caption {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-top: 10px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .biography-container {
    flex-direction: column;
  }

  .biography-title {
    font-size: 28px;
    border-left: 5px solid #fb196c;
  }

  .biography-text {
    font-size: 14px;
  }

  .biography-image img {
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  
  .biography-container {
    text-align: center;
  }

  .biography-title {
    text-align: center;
    padding-left: 0;
    border-left: none;
    border-left: 5px solid #fb196c;
  }

  .biography-quote {
    padding: 30px 15px;
  }

  .biography-quote p {
    font-size: 18px;
  }

  .biography-quote-author {
    font-size: 16px;
  }

  .biography-highlight {
    text-align: center;
  }

  .gallery-item {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .biography-title {
    font-size: 24px;
  }

  .biography-text {
    font-size: 12px;
  }

  .biography-highlight {
    font-size: 14px;
  }
}
.policies-page {
    width: 100%;
    padding: 60px 20px;
    background: #f9f9f9;
    font-family: "Poppins", sans-serif;
  }
  
  .policies-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 40px;
    gap: 40px;
  }

  .main-policy-item {
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  
  .policy-item {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  
  .main-policy {
    grid-column: span 2; /* Main policy spans across columns */
  }

  .main-policy-description{
    font-size: 17px;
    color: #555;
    line-height: 1.6;
  }
  
  .policy-title {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
    text-transform: uppercase;
  }
  
  .policy-subtitle {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
    color: #fe0202;
    display: flex;
    align-items: center;
  }
  
  .policy-description {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
  }
  
  .policy-icon {
    font-size: 36px;
    color: #fe0202;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .main-policy {
      grid-column: span 1; /* Main policy spans single column on small screens */
    }
  }
.contact-page {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(0, 0, 0, 0.1)
    ), 
    url(/static/media/contact-page.0ac83d28.jpg) no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  color: #fff;
}

/* Container for sections */
.contact-container {
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  max-width: 1200px;
  grid-gap: 50px;
  gap: 50px; /* Adjust gap for better spacing */
}

/* Individual sections */
.where-to-find-us,
.drop-us-a-line {
  flex: 1 1;
}

.where-to-find-us {
  margin-right: 20px;
}

.section-title {
  font-size: 54px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Locations Styling */
.location {
  display: flex;
  align-items: center; /* Center-align icon and text */
  margin-bottom: 30px; /* Space between each location */
  font-size: 18px; /* Text size for the details */
  flex-wrap: nowrap; /* Prevent wrapping of the icon and text */
  grid-gap: 15px;
  gap: 15px; /* Space between the icon and text */
}

.icon1 {
  font-size: 36px; /* Standard size for desktop */
  color: #fe0202; /* Red color for the icon */
  margin-right: 20px; /* Space between icon and text */
  border: 2px solid #fe0202; /* Red border for the circle */
  border-radius: 50%; /* Make it circular */
  padding: 10px; /* Add space inside the circle */
  display: flex; /* Flexbox for proper alignment */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  width: 60px; /* Fixed width to maintain the circular shape */
  height: 60px; /* Fixed height to match width */
  box-sizing: border-box; /* Include padding and border in size */
  flex-shrink: 0; /* Prevent shrinking on smaller screens */
}

.location-details p {
  margin: 0 0 10px;
  font-size: 20px;
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
}

.location-details p:last-child {
  margin-bottom: 0;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-group label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  pointer-events: none;
  transition: all 0.3s ease;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  border-bottom: 2px solid #ddd;
  background: transparent;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-bottom-color: #000;
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
  top: -10px;
  font-size: 12px;
  color: #fff;
  background: #fe0202;
  padding: 0 5px;
}

.form-group textarea {
  resize: none;
  height: 100px;
}

.send-message-btn {
  width: 150px;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  background: transparent;
  border: 2px solid #000;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.send-message-btn:hover {
  background: #000;
  color: #fff;
}

.contact-form h2 {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .contact-container {
    grid-gap: 30px;
    gap: 30px; /* Adjust gap for smaller screens */
  }
  
}

@media (max-width: 768px) {
  .icon1 {
    font-size: 28px; /* Adjust icon size for mobile */
    width: 50px; /* Smaller width */
    height: 50px; /* Smaller height */
    padding: 8px; /* Adjust padding */
    margin-right: 0; /* Remove right margin to center icon */
  }

  .location {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center align both icon and text */
    text-align: center; /* Center the text underneath the icon */
    grid-gap: 10px;
    gap: 10px; /* Add spacing between icon and text */
    
  }

  .location-details p {
    font-size: 18px; /* Adjust font size for better readability */
  }

  .contact-container {
    flex-direction: column; /* Stack sections vertically */
    grid-gap: 30px;
    gap: 30px; /* Add spacing between sections */
  }

  .where-to-find-us,
  .drop-us-a-line {
    width: 100%;
  }

  .send-message-btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .icon1 {
    font-size: 24px; /* Slightly smaller icon size for very small screens */
    width: 40px; /* Reduce width for smaller devices */
    height: 40px; /* Reduce height for smaller devices */
    padding: 6px; /* Adjust padding */
    margin-right: 0; /* No margin-right to ensure centering */
  }

  .location {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center align icon and text */
    text-align: center; /* Center-align text below the icon */
    grid-gap: 8px;
    gap: 8px; /* Reduce gap between icon and text */
  }

  .location-details p {
    font-size: 14px; /* Smaller font size for better fit */
    line-height: 1.4; /* Adjust line height for readability */
  }

  .contact-container {
    flex-direction: column; /* Stack sections vertically */
    grid-gap: 20px;
    gap: 20px; /* Reduce spacing for tighter screens */
    padding: 10px; /* Add padding around the container */
  }

  .section-title {
    font-size: 36px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px;
  }

  .send-message-btn {
    font-size: 12px;
    padding: 10px;
  }
}

@media (max-width: 393px) {
  .contact-container {
    flex-direction: column; /* Stack items */
    grid-gap: 30px;
    gap: 30px; /* Adjust gap */
    text-align: center; /* Center align content */
  }

  .section-title{
    padding-top: 40px;
  }

  .location {
    justify-content: center; /* Center content horizontally */
    flex-direction: column; /* Stack icon and text vertically */
    text-align: center;
  }

  .icon1 {
    margin: 0 auto 10px; /* Center icon and add spacing below */
    font-size: 28px; /* Adjust icon size for smaller screens */
    width: 50px;
    height: 50px;
  }

  .location-details p {
    font-size: 16px; /* Reduce text size */
  }

  .send-message-btn {
    width: 100%; /* Full width for button */
    text-align: center;
  }
}
