.contact-page {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(0, 0, 0, 0.1)
    ), 
    url("../../imgs/contact-page.jpg") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  color: #fff;
}

/* Container for sections */
.contact-container {
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  max-width: 1200px;
  gap: 50px; /* Adjust gap for better spacing */
}

/* Individual sections */
.where-to-find-us,
.drop-us-a-line {
  flex: 1;
}

.where-to-find-us {
  margin-right: 20px;
}

.section-title {
  font-size: 54px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Locations Styling */
.location {
  display: flex;
  align-items: center; /* Center-align icon and text */
  margin-bottom: 30px; /* Space between each location */
  font-size: 18px; /* Text size for the details */
  flex-wrap: nowrap; /* Prevent wrapping of the icon and text */
  gap: 15px; /* Space between the icon and text */
}

.icon1 {
  font-size: 36px; /* Standard size for desktop */
  color: #fe0202; /* Red color for the icon */
  margin-right: 20px; /* Space between icon and text */
  border: 2px solid #fe0202; /* Red border for the circle */
  border-radius: 50%; /* Make it circular */
  padding: 10px; /* Add space inside the circle */
  display: flex; /* Flexbox for proper alignment */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  width: 60px; /* Fixed width to maintain the circular shape */
  height: 60px; /* Fixed height to match width */
  box-sizing: border-box; /* Include padding and border in size */
  flex-shrink: 0; /* Prevent shrinking on smaller screens */
}

.location-details p {
  margin: 0 0 10px;
  font-size: 20px;
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
}

.location-details p:last-child {
  margin-bottom: 0;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-group label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  pointer-events: none;
  transition: all 0.3s ease;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  border-bottom: 2px solid #ddd;
  background: transparent;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-bottom-color: #000;
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
  top: -10px;
  font-size: 12px;
  color: #fff;
  background: #fe0202;
  padding: 0 5px;
}

.form-group textarea {
  resize: none;
  height: 100px;
}

.send-message-btn {
  width: 150px;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  background: transparent;
  border: 2px solid #000;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.send-message-btn:hover {
  background: #000;
  color: #fff;
}

.contact-form h2 {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .contact-container {
    gap: 30px; /* Adjust gap for smaller screens */
  }
  
}

@media (max-width: 768px) {
  .icon1 {
    font-size: 28px; /* Adjust icon size for mobile */
    width: 50px; /* Smaller width */
    height: 50px; /* Smaller height */
    padding: 8px; /* Adjust padding */
    margin-right: 0; /* Remove right margin to center icon */
  }

  .location {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center align both icon and text */
    text-align: center; /* Center the text underneath the icon */
    gap: 10px; /* Add spacing between icon and text */
    
  }

  .location-details p {
    font-size: 18px; /* Adjust font size for better readability */
  }

  .contact-container {
    flex-direction: column; /* Stack sections vertically */
    gap: 30px; /* Add spacing between sections */
  }

  .where-to-find-us,
  .drop-us-a-line {
    width: 100%;
  }

  .send-message-btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .icon1 {
    font-size: 24px; /* Slightly smaller icon size for very small screens */
    width: 40px; /* Reduce width for smaller devices */
    height: 40px; /* Reduce height for smaller devices */
    padding: 6px; /* Adjust padding */
    margin-right: 0; /* No margin-right to ensure centering */
  }

  .location {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center align icon and text */
    text-align: center; /* Center-align text below the icon */
    gap: 8px; /* Reduce gap between icon and text */
  }

  .location-details p {
    font-size: 14px; /* Smaller font size for better fit */
    line-height: 1.4; /* Adjust line height for readability */
  }

  .contact-container {
    flex-direction: column; /* Stack sections vertically */
    gap: 20px; /* Reduce spacing for tighter screens */
    padding: 10px; /* Add padding around the container */
  }

  .section-title {
    font-size: 36px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px;
  }

  .send-message-btn {
    font-size: 12px;
    padding: 10px;
  }
}

@media (max-width: 393px) {
  .contact-container {
    flex-direction: column; /* Stack items */
    gap: 30px; /* Adjust gap */
    text-align: center; /* Center align content */
  }

  .section-title{
    padding-top: 40px;
  }

  .location {
    justify-content: center; /* Center content horizontally */
    flex-direction: column; /* Stack icon and text vertically */
    text-align: center;
  }

  .icon1 {
    margin: 0 auto 10px; /* Center icon and add spacing below */
    font-size: 28px; /* Adjust icon size for smaller screens */
    width: 50px;
    height: 50px;
  }

  .location-details p {
    font-size: 16px; /* Reduce text size */
  }

  .send-message-btn {
    width: 100%; /* Full width for button */
    text-align: center;
  }
}