@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@300;400;700&display=swap');

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(20px); /* Slight downward offset for animation */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.biography-section {
  background: linear-gradient(to bottom, #ffffff, #f0f0f0);
  padding: 80px 40px;
  color: #222;
}

.biography-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.biography-image img {
  width: 100%;
  height: auto;
  max-width: 450px;
  border-radius: 20px;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  animation: fadeInText 1s ease-in-out;
}

.biography-content {
  flex: 1;
  max-width: 600px;
}

.biography-title {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: #111;
  margin-bottom: 20px;
  text-transform: capitalize;
  border-left: 5px solid #fb196c;
  padding-left: 15px;
  animation: fadeInText 1s ease-in-out;
  text-transform: uppercase;
}

.biography-text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 25px;
  text-align: justify;
  animation: fadeInText 1.2s ease-in-out;
}

.biography-quote {
  position: relative;
  padding: 40px 20px;
  margin: 40px auto;
  max-width: 800px;
  background: linear-gradient(135deg, rgba(251, 25, 108, 0.1), rgba(251, 176, 59, 0.1));
  border-left: 5px solid #fb196c; /* Gradient accent line */
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #333;
  animation: fadeInText 1.4s ease-in-out;
}

.biography-quote::before {
  content: "“";
  position: absolute;
  top: -20px;
  left: 20px;
  font-size: 50px;
  color: rgba(251, 25, 108, 0.3); /* Light accent color for decorative quotation mark */
  font-weight: bold;
}

.biography-quote p {
  font-size: 20px;
  line-height: 1.8;
  font-weight: 400;
  margin: 0;
  padding: 10px 0;
}

.biography-quote-author {
  font-size: 18px;
  font-weight: 600;
  color: #fb196c;
  margin-top: 20px;
  text-transform: uppercase;
  animation: fadeInText 1.6s ease-in-out;
}

.biography-highlight {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #fb196c;
  text-align: justify;
  margin-top: 30px;
  line-height: 1.8;
}

.biography-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid layout */
  gap: 20px;
  margin-top: 40px;
}


.gallery-item {
  width: 100%;
  height: 200px; /* Set a fixed height for all items */
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 100%; /* Match the container's height */
  object-fit: cover; /* Ensure the image covers the entire area without distortion */
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.gallery-item:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Add depth on hover */
}

.gallery-caption {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-top: 10px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .biography-container {
    flex-direction: column;
  }

  .biography-title {
    font-size: 28px;
    border-left: 5px solid #fb196c;
  }

  .biography-text {
    font-size: 14px;
  }

  .biography-image img {
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  
  .biography-container {
    text-align: center;
  }

  .biography-title {
    text-align: center;
    padding-left: 0;
    border-left: none;
    border-left: 5px solid #fb196c;
  }

  .biography-quote {
    padding: 30px 15px;
  }

  .biography-quote p {
    font-size: 18px;
  }

  .biography-quote-author {
    font-size: 16px;
  }

  .biography-highlight {
    text-align: center;
  }

  .gallery-item {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .biography-title {
    font-size: 24px;
  }

  .biography-text {
    font-size: 12px;
  }

  .biography-highlight {
    font-size: 14px;
  }
}