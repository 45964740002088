/* General styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #fff;
  margin: 0;
  padding: 0;
}

.video-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 20px; /* Added top padding to prevent overlap */
}

/* Main video styles */
.main-video {
  position: relative;
  margin-bottom: 20px;
}

.main-video-thumbnail {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.main-video-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 8px;
}

.main-video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
}

.main-video-title {
  font-size: 36px;
  font-weight: 700;
}

.main-video-play-icon {
  font-size: 64px;
  color: #fff;
  margin-top: 10px;
}

/* Video section styles */
.video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
}

.video-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, box-shadow 0.3s;
}

.video-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
}

.video-thumbnail {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.video-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 8px;
}

.video-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Loading message styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

/* End message styles */
.end-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #b3b3b3;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .video-page-container {
    padding: 70px 20px 20px; /* Increase top padding for mobile */
  }

  .main-video-title {
    font-size: 28px;
  }

  .main-video-play-icon {
    font-size: 48px;
  }

  .video-title {
    font-size: 16px;
  }
}