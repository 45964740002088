@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap');


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background-color: transparent; /* Transparent by default */
  box-sizing: border-box; /* Ensure consistent padding */
 
}

.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.886); /* Light background when scrolled */
}

.navbar.scrolled .navbar-left a,
.navbar.scrolled .navbar-right a {
  color: black; /* Black text when scrolled */
  font-family: 'Archivo', sans-serif;
}

.navbar.scrolled .menu-icon {
  color: black; /* Black menu icon when scrolled */
  font-family: 'Archivo', sans-serif;
}

.navbar-left,
.navbar-center,
.navbar-right {
  flex: 1; /* Equal space for each section */
  display: flex;
  align-items: center;
}

.navbar-left {
  justify-content: flex-start;
}

.navbar-center {
  justify-content: center;
}

.navbar-right {
  justify-content: flex-end; /* Align links to the right */
}

.logo-image {
  height: 40px;
  display: block;
}

.navbar-left a,
.navbar-right a {
  position: relative; /* Needed for the pseudo-element */
  color: rgb(199, 199, 199); /* Default text color */
  margin: 0 15px;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: color 0.3s ease; /* Smooth color transition */
  font-family: 'Archivo', sans-serif;
}

.navbar-left a::after,
.navbar-right a::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Position the underline just below the text */
  left: 50%; /* Start underline from the center */
  width: 0; /* Start with no width */
  height: 2px; /* Thickness of the underline */
  background-color: #ea0000; /* Red underline color */
  transform: translateX(-50%); /* Center-align the underline */
  transition: width 0.3s ease; /* Smooth expand effect */
}

.navbar-left a:hover,
.navbar-right a:hover {
  color: #e4e4e4; /* Change text color on hover */
}

.navbar-left a:hover::after,
.navbar-right a:hover::after {
  width: 100%; /* Expand the underline to match the text width */
}

/* Mobile Navbar Styles */
.navbar-mobile {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.navbar-menu {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 999;
  padding: 20px;
}

.navbar-menu.open {
  display: flex;
}

.navbar-menu a {
  color: white;
  font-family: 'Archivo', sans-serif;
  margin: 10px 0;
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.navbar-menu a:hover {
  color: #edebeb;
}

.social-icons {
  display: flex; /* Use flexbox for alignment */
  gap: 15px; /* Add spacing between icons */
  margin-top: 20px; /* Add spacing above the icons */
}

.social-icons a {
  color: white; /* Default icon color */
  font-size: 24px; /* Size of the icons */
  transition: color 0.3s ease; /* Smooth transition for hover */
}

.social-icons a:hover {
  color: #f92525; /* Change icon color on hover */
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .navbar {
    background-color: #ffffff;
  }

  .menu-icon {
    color: black;
  }

  .navbar-left,
  .navbar-center,
  .navbar-right {
    display: none;
  }

  .navbar-mobile {
    display: flex;
  }
}