/* Container Styles */
.services-container {
  padding: 100px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Oswald", sans-serif;
  overflow: hidden; /* Ensure animations stay within bounds */
}

/* Intro Section */
.services-intro {
  text-align: center;
  margin-bottom: 50px;
  animation: fadeIn 1.5s ease-in-out;
}

.intro-title {
  font-size: 36px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.intro-description {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* Services Section */
.services-title {
  font-size: 50px;
  text-align: center;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  animation: slideIn 1.5s ease-in-out;
}

.services-divider {
  width: 35%;
  height: 3px;
  background-color: #f92525;
  margin: 0 auto 50px auto;
  animation: slideIn 1.5s ease-in-out;
}

/* Services Section with Improved Divider */
.service-item {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 60px;
  position: relative;
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start slightly shifted */
  animation: slideUp 1.2s ease-in-out forwards;
}

.service-item:nth-child(even) {
  flex-direction: row-reverse;
}

.service-item:nth-child(odd) {
  animation-delay: 0.3s; /* Staggered animation for odd rows */
}

.service-item:nth-child(even) {
  animation-delay: 0.6s; /* Staggered animation for even rows */
}

/* Divider Styling */
.service-item {
  border-left: 2px solid #ce3030;
}

.service-item:nth-child(even) {
  border-right: 2px solid #ce3030;
  border-left: none;
}

/* Text Content Styling */
.service-text {
  max-width: 600px;
  flex: 1;
  padding: 0 20px;
}

.service-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
  animation: fadeIn 1.2s ease-in-out;
}

.service-description {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  animation: fadeIn 1.5s ease-in-out;
}

/* Image Styling */
.service-image {
  flex: 1;
  max-width: 500px;
  animation: zoomIn 1.5s ease-in-out;
}

.service-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.book-session-container {
  text-align: center;
  margin-top: 40px;
}

@keyframes smoothBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Slight upward movement */
  }
}

.book-session-container {
  text-align: center;
  margin-top: 40px;
}

.book-session-button {
  display: inline-block;
  background-color: #f92525;
  color: white;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  animation: smoothBounce 3s ease-in-out infinite; /* Smooth bounce with easing */
}

.book-session-button:hover {
  background-color: #d11414;
  transform: translateY(-2px); /* Subtle hover lift effect */
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .service-item {
    flex-direction: column;
    text-align: center;
    gap: 30px;
    border: none;
  }

  .service-text {
    max-width: 100%;
    padding: 0;
    text-align: center;
  }

  .service-image {
    padding: 0;
  }

  .service-image img {
    margin: 0 auto;
  }

  .intro-title {
    font-size: 28px;
  }

  .intro-description {
    font-size: 16px;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}