.projects-section {
  background: url("../../imgs/artists_walls.jpg") center/cover no-repeat;
  position: relative;
  text-align: center;
  padding: 80px 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.projects-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.4), /* Darker overlay */
      rgba(0, 0, 0, 0.6)
    ),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
  opacity: 1;
  z-index: 0;
}

.content-container {
  z-index: 2;
  max-width: 600px;
  position: relative;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px; /* Smooth corners for better aesthetics */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for better contrast */
}

.headline {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: #ffffff; /* Change text color to white for contrast */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8); /* Add text shadow */
}

.subheadline {
  font-size: 18px;
  color: #dddddd; /* Subtle color for subheadline text */
  margin-top: 10px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8); /* Add text shadow */
}

.cover-art-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
}

.cover-art {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: float 6s ease-in-out infinite;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}

.cover-art:hover {
  transform: scale(1.1);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .headline {
    font-size: 32px;
  }
  .subheadline {
    font-size: 14px;
  }
  .cover-art {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 480px) {
  .headline {
    font-size: 28px;
  }
  .subheadline {
    font-size: 12px;
  }
  .cover-art {
    width: 70px;
    height: 70px;
  }
}