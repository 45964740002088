@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

.hero-wrap {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5)), 
                url('../../imgs/the-lab3.jpg') center center/cover no-repeat; /* Two-tone gradient overlay */
}

/* Optional animation for the gradient overlay */
.hero-wrap::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.2)); /* Subtle gradient animation */
    z-index: 1;
     
}


.hero-container {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: flex-start; /* Aligns content to the left */
    height: 100%;
    width: 100%;
    color: white;
    padding: 20px 40px; /* Adjusted padding */
    animation: slideUp 1.5s ease-in-out 0.5s forwards; /* Slide-up effect with delay */
}

/* Text content styling */
.text-content {
    max-width: 640px;
    margin-bottom: 30px; /* Spacing below text content */
}

/* Apply the typing effect */
.hero-container h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 48px; /* Default size */
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid white;
    opacity: 0; /* Initially hide the text */
    animation: typingEffect 3s steps(30, end) 0.5s forwards, blinkCursor 0.5s step-end infinite; /* Add delay of 0.5s */
    max-width: 100%;
    animation-fill-mode: forwards; /* Retain final state after animation */
}

/* Stop cursor blink after typing */
.hero-container h1.typed {
    border-right: none; /* Remove the cursor after the animation finishes */
    animation: none; /* Disable further animations */
}

.text-content p {
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0;
    color: #ececec; /* Subtle contrast color */
    opacity: 0; /* Hidden initially for animation */
    animation: textFadeUp 4.3s ease-in-out 1s forwards; /* Staggered fade-up for the paragraph */
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes overlayFade {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.5);
    }
}


@keyframes textFadeUp {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Typing animation */
@keyframes typingEffect {
    0% {
        width: 0; /* Start with no visible text */
        opacity: 1; /* Make the text visible during animation */
    }
    100% {
        width: 100%; /* Fully reveal the text */
        opacity: 1; /* Ensure the text remains visible */
    }
}

/* Cursor blinking animation */
@keyframes blinkCursor {
    0% {
        border-right-color: transparent;
    }
    50% {
        border-right-color: white;
    }
    100% {
        border-right-color: transparent;
    }
}

@keyframes typingEffectMobile {
    from {
        width: 0; /* Start with no visible text */
        opacity: 0; /* Make the text visible during animation */
    }
    to {
        width: 58%; /* Reveal the entire text */
        opacity: 1; /* Make the text visible during animation */
    }
}

/* Responsive font sizes */
@media (max-width: 480px) {
    .hero-container {
        padding: 15px 5px; /* Further reduce padding */
    }

    .hero-container h1 {
        font-size: 22px; /* Reduce font size further */
        animation: typingEffectMobile 3s steps(30, end) forwards, blinkCursor 0.5s step-end infinite;
        /* animation: typingEffect 3s steps(30, end) 0.5s forwards, blinkCursor 0.5s step-end infinite; Add delay of 0.5s */
    }

    .text-content p {
        font-size: 12px; /* Reduce paragraph size */
    }

   
}

@media (min-width: 768px) {
    .hero-container h1 {
        font-size: 63px; /* Medium screens */
        
    }
    .text-content p {
        font-size: 20px; /* Medium screens */
    }
}

@media (min-width: 992px) {
    .hero-container h1 {
        font-size: 63px; /* Large screens */
    }
    .text-content p {
        font-size: 22px; /* Large screens */
    }
}

@media (min-width: 1200px) {
    .hero-container h1 {
        font-size: 64px; /* Extra large screens */
    }
    .text-content p {
        font-size: 24px; /* Extra large screens */
    }
}


