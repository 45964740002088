@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap');

/* General Footer Styling */
/* General Footer Styling */
.simple-footer {
  background-color: #fff; /* White background */
  color: #000; /* Black text */
  text-align: center;
  padding: 40px 20px; /* Increase top padding for overall spacing */
  border-top: 1px solid #ddd; /* Subtle top border */
}

/* Logo Section */
.footer-logo-container {
  margin-bottom: 30px; /* Increase spacing below the logo */
  margin-top: 20px; /* Add spacing above the logo */
}

.footer-logo {
  max-width: 250px; /* Adjusted for smaller screens */
  display: block;
  margin: 0 auto;
  height: auto;
}

/* Navigation Section */
.footer-nav {
  display: flex;
  justify-content: center;
  gap: 30px; /* Increase spacing between navigation links */
  flex-wrap: wrap; /* Wrap items to the next line if necessary */
  margin-top: 20px; /* Add spacing above the navigation */
  margin-bottom: 30px; /* Add spacing below the navigation */
}

.footer-nav a {
  color: #000; /* Black text */
  text-decoration: none; /* Remove default underline */
  font-size: 14px;
  /* font-weight: bold; */
  text-transform: uppercase;
  position: relative; /* Required for the pseudo-element */
  display: inline-block; /* Prevents pseudo-element from extending */
  transition: color 0.3s ease; /* Smooth color transition */
  text-align: center; /* Center-align the text */
  font-family: 'Archivo', sans-serif;
}

.footer-nav a::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Position the underline just below the text */
  left: 50%; /* Start underline from the center */
  width: 0; /* Start with no width */
  height: 2px; /* Thickness of the underline */
  background-color: #ea0000; /* Red underline color */
  transform: translateX(-50%); /* Center-align the underline */
  transition: width 0.3s ease; /* Smooth expand effect */
}

.footer-nav a:hover {
  color: #ea0000; /* Change text color on hover */
}

.footer-nav a:hover::after {
  width: 100%; /* Expand the underline to match the text width */
}

/* Bottom Section */
.footer-bottom {
  font-size: 12px;
  color: #666; /* Light gray text */
  border-top: 1px solid #ddd; /* Subtle top border for bottom section */
  padding-top: 20px; /* Increase spacing above the bottom section */
  font-family: 'Archivo', sans-serif;
  text-transform: uppercase;
  margin-top: 30px; /* Add spacing above the footer-bottom */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-logo {
    max-width: 250px; /* Adjust the logo size for smaller screens */
  }

  .footer-nav {
    gap: 20px; /* Adjust spacing between navigation links */
    flex-direction: column; /* Stack navigation links vertically */
    margin-top: 15px; /* Adjust spacing above for smaller screens */
    margin-bottom: 20px; /* Adjust spacing below for smaller screens */
  }

  .footer-nav a {
    font-size: 14px; /* Slightly smaller font size for links */
  }

  .footer-bottom {
    font-size: 10px; /* Adjust font size for better readability on smaller screens */
    margin-top: 20px; /* Adjust spacing above footer-bottom */
  }
}

@media (max-width: 480px) {
  .footer-logo {
    max-width: 200px; /* Smaller logo for very small screens */
  }

  .footer-nav {
    gap: 15px; /* Further adjust spacing for tighter spaces */
  }

  .footer-nav a {
    font-size: 13px; /* Smaller font for links */
  }

  .footer-bottom {
    font-size: 9px; /* Further adjust font size for smaller screens */
  }
}