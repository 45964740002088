.policies-page {
    width: 100%;
    padding: 60px 20px;
    background: #f9f9f9;
    font-family: "Poppins", sans-serif;
  }
  
  .policies-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 40px;
  }

  .main-policy-item {
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .policy-item {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .main-policy {
    grid-column: span 2; /* Main policy spans across columns */
  }

  .main-policy-description{
    font-size: 17px;
    color: #555;
    line-height: 1.6;
  }
  
  .policy-title {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
    text-transform: uppercase;
  }
  
  .policy-subtitle {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
    color: #fe0202;
    display: flex;
    align-items: center;
  }
  
  .policy-description {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
  }
  
  .policy-icon {
    font-size: 36px;
    color: #fe0202;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .main-policy {
      grid-column: span 1; /* Main policy spans single column on small screens */
    }
  }