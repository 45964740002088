.addons-section {
    font-family: 'Oswald', sans-serif;
    padding: 80px 20px;
    background-color: #f8f9fa; /* Light gray background for contrast */
  }
  
  .addons-content {
    text-align: center;
    margin-bottom: 40px;
  }

  .addon-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .addon-icon {
    font-size: 24px; /* Adjust icon size */
    color: #f92525; /* Match your color scheme */
  }
  
  .addons-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .addons-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  /* Addons Grid */
  .addons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  /* Addon Cards */
  .addon-card {
    background-color: #ffffff; /* White background for the cards */
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .addon-card:hover {
    transform: translateY(-5px); /* Lift card slightly on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }
  
  .addon-title {
    font-size: 20px;
    font-weight: 700;
    color: #f92525; /* Red for emphasis */
    margin-bottom: 10px;
  }
  
  .addon-description {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
  }
  
  /* Pricing Section */
  .pricing-section {
    text-align: center;
  }
  
  .pricing-title {
    font-size: 32px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .pricing-subtitle {
    font-size: 20px;
    margin-bottom: 30px;
    color: #555;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .pricing-card {
    background: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .plan-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .plan-duration {
    font-size: 14px;
    color: #bbb;
    margin-bottom: 20px;
  }
  
  .plan-details {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .plan-details li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .buy-now-button {
    background: #5b5eff;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .buy-now-button:hover {
    background: #4646d6;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .addons-grid,
    .pricing-grid {
      grid-template-columns: 1fr;
    }
  }